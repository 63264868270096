import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from '@app/app.service';
import { Observable } from 'rxjs';
import { IChangeHistoryResponse } from '@main/change-history/change-history.model';

@Injectable()
export class ChangeHistoryService {
  constructor(private http: HttpClient) {}
  /**
   * Load auto change-history list from server via HTTP Request
   *
   * API endpoint: [/api/v1/change_history/history/]
   *
   * @param params
   * @returns {Observable<IChangeHistoryResponse>}
   */
  public list(params?: any): Observable<IChangeHistoryResponse> {
    return this.http.get<IChangeHistoryResponse>(AppService.getAppUri(`/change_history/change_history/`), { params: params });
  }
}
